import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactHtmlParser from "react-html-parser";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faFaceMehBlank } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Card from "react-bootstrap/Card";
import "./blogdetail.css";
import user from "../../assets/Cody_Foster.webp";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const Secondsec = () => {
  const [posttitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, SetError] = useState("");
  const [tags, setTags] = useState([]);
  const [date, setDate] = useState("");
  const params = useParams();
  let { slug } = useParams();

  const styles = {
    width: "100%",
  };

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-blog/${params.slug}`
    );
    result = await result.json();
    setName(result.posttitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setFileName(result.document);
    setMetatitle(result.metatitle);
    setMetadescription(result.metadescription);
    setTags(result.tags);
    setDate(result.date); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-posts`
    );
    const result = await response.json();
    const post = result.find((post) => post.slug === slug); // find the current post based on the slug

    setTags(post.tags); // set the tags for the current post
    setMetatitle(post.metatitle); // set the meta title for the current post
    setMetadescription(post.metadescription); // set the meta description for the current post
    setDate(
      new Date(parseInt(post._id.substring(0, 8), 16) * 1000).toLocaleString()
    ); // set the date for the current post
    setData([{ ...post }]); // set the data for the current post with the selectedTeam details
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <head>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        {fileName && (
          <meta
            property="og:image"
            content={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
          />
        )}
      </head>
      <section className="blog-detail-secod-sec">
        <Container>
          <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {fileName && (
            <img
              style={styles}
              src={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
              alt="Your Image Alt Text"
            />
          )}

          <Row>
            <Col className="">
              {/* <h1>{posttitle}</h1> */}
              <p className="blog-date">{date}</p>
              <p className="blog-inner-detail-desc">
                {ReactHtmlParser(description)}
              </p>
              {/* <Row className='py-5'>
            <h2 className='py-3'>Author</h2>
                <Col md={1}>
                    <img className='blogu-icon-details' src={user}/>
                </Col>
                <Col md={11} className="px-5">
                    <h6 className='blog-author'>Cody Foster</h6>
                    <p>Digital Marketing Manager, Joot</p>
                </Col>
            </Row> */}
              {/* <h2>Topics</h2>
            <Button variant="outline-light" className="blogtag-btn">{tags}</Button> */}

              <Row className="py-5 article-share-icons">
                <h2 className="py-3">Share this Article</h2>
                <Col className="article-icons-row" xs={5}>
                  <div>
                    <FacebookShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        size="3x"
                        className="px-2"
                      />
                    </FacebookShareButton>
                    <LinkedinShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        size="3x"
                        className="px-2"
                      />
                    </LinkedinShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size="3x"
                        className="px-2"
                      />
                    </TwitterShareButton>
                  </div>
                </Col>
                <Link to={"/blog"}>
                  <Button>View More Posts</Button>
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
